import React, { useEffect, useMemo, useState } from "react";
import Layout from "../Layout";
import { PaymentService } from "../../services/PaymentService";
import Table from "../../components/Table";
import { format, parseISO } from "date-fns";
import axios from "axios";
import Input from "../../components/Forms/Input";
import Select from "../../components/Forms/Select";
import debounce from "lodash.debounce";
import Button from "../../components/Buttons/Button";
import Notification from "../../components/Alerts/Notification";

export default function Payments() {
  const [payments, setPayments] = useState([]);
  const [currentPaginationLink, setCurrentPaginationLink] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState({
    state: false,
    title: "",
    status: "success",
    message: "",
  });
  const [showErrorAlert, setShowErrorAlert] = useState({
    state: false,
    title: "",
    status: "error",
    message: "",
  });
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    status: "",
    payment_method: "",
    reference: "",
    invoice_code: "",
    customer: "",
    description: "",
  });

  const debouncedInputFilter = useMemo(
    () =>
      debounce(
        (e) =>
          setFilters((filters) => ({
            ...filters,
            [e.target.name]: e.target.value,
          })),
        1000
      ),
    []
  );

  const handleInputFilter = (e) => {
    debouncedInputFilter(e);
  };

  useEffect(() => {
    PaymentService.payments(filters)
      .then((response) => {
        setPayments(response.data);
      })
      .catch((_) => {
        setShowErrorAlert({
          state: true,
          title: "Unable to get payments.",
          status: "error",
          message:
            "Unfortunately payments could not be loaded. Please try agian or contact suppor!",
        });
      });
  }, [filters]);

  useEffect(() => {
    if (currentPaginationLink !== "") {
      axios
        .get(currentPaginationLink, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setPayments(response.data);
        })
        .catch((_) => {
          setShowErrorAlert({
            state: true,
            title: "Unable to get payments for filters",
            status: "error",
            message:
              "Unfortunately payments could not be loaded. Please try agian or contact suppor!",
          });
        });
    }
  }, [currentPaginationLink]);

  const verifyPayment = (e, payment) => {
    e.preventDefault();

    PaymentService.verifyPayment(payment)
      .then((response) => {
        if (response?.data?.status) {
          setShowSuccessAlert({
            state: true,
            title: "Payment successfully Verified",
            status: "success",
            message: "Payment verification was successful!",
          });
          setShowErrorAlert({
            state: false,
            status: "error",
            title: "Unable to verify payment at this time.",
            message: "Payment could not be verified. Please try again later.",
          });
        } else {
          setShowSuccessAlert({ state: false });
          setShowErrorAlert({
            state: true,
            status: "error",
            title: "Unable to verify payment at this time.",
            message: "Payment could not be verified. Please try again later.",
          });
        }
      })
      .catch((_) => {
        setShowSuccessAlert({ state: false });
        setShowErrorAlert({
          state: true,
          status: "error",
          title: "Unable to verify payment at this time.",
          message: "Payment could not be verified. Please try again later.",
        });
      });
  };
  return (
    <Layout>
      <div>
        <h3 className="text-xl font-bold dark:text-white">Payments</h3>
        <span className="flex mt-2 text-sm font-medium text-gray-400">
          View and manage payments.
        </span>
      </div>
      <div className="px-4 py-4 mt-4 bg-gray-100 rounded-lg">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
          <div className="grid-cols-1">
            <label htmlFor="customer" className="text-sm">
              Search Customers
            </label>
            <Input
              name="customer"
              id="customer"
              type="search"
              className="w-full mt-2"
              placeholder="Search by customer first name, last name or business name"
              onChange={handleInputFilter}
            />
          </div>
          <div className="grid-cols-1">
            <label htmlFor="code" className="text-xs">
              Invoice Code
            </label>
            <Input
              name="invoice_code"
              id="code"
              placeholder="Invoice code"
              aria-label="invoice code"
              className="flex w-full mt-2"
              onChange={handleInputFilter}
            />
          </div>
          <div className="grid-cols-1">
            <label htmlFor="start_date" className="text-xs">
              Start Date
            </label>
            <Input
              name="start_date"
              id="start_date"
              type="date"
              aria-label="start date to filter payments"
              className="flex w-full mt-2"
              onChange={(e) =>
                setFilters({ ...filters, start_date: e.target.value })
              }
            />
          </div>

          <div className="grid-cols-1">
            <label htmlFor="end_date" className="text-xs">
              End Date
            </label>
            <Input
              name="end_date"
              id="end_date"
              type="date"
              aria-label="end date for filter between date period"
              className="flex w-full mt-2"
              onChange={(e) =>
                setFilters({ ...filters, end_date: e.target.value })
              }
            />
          </div>

          <div className="grid-cols-1">
            <label htmlFor="status" className="text-xs">
              Status
            </label>
            <Select
              id="status"
              className="flex w-full mt-2"
              onChange={(e) =>
                setFilters({
                  ...filters,
                  status: e.target.value,
                })
              }
            >
              <option value="">Select Status</option>
              <option value="Successful">Successful</option>
              <option value="Pending">Pending</option>
              <option value="Failed">Failed</option>
            </Select>
          </div>

          <div className="grid-cols-1">
            <label htmlFor="reference" className="text-xs">
              Payment Reference
            </label>
            <Input
              defaultValue={filters.reference}
              name="reference"
              id="reference"
              aria-label="payment reference"
              placeholder="Payment reference"
              className="flex w-full mt-2"
              onChange={handleInputFilter}
            />
          </div>

          <div className="grid-cols-1">
            <label htmlFor="method" className="text-xs">
              Payment Method
            </label>
            <Select
              id="met"
              className="flex w-full mt-2"
              onChange={(e) =>
                setFilters({
                  ...filters,
                  payment_method: e.target.value,
                })
              }
            >
              <option value="">Select Method</option>
              <option value="Cash">Cash</option>
              <option value="Card">Card</option>
              <option value="Mobile Money">Mobile Money</option>
            </Select>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="text-sm text-brand-primary"
            onClick={() =>
              setFilters({
                start_date: "",
                end_date: "",
                status: "",
                payment_method: "",
                reference: "",
                invoice_code: "",
                customer: "",
              })
            }
          >
            Clear Filters
          </button>
        </div>
      </div>
      {showErrorAlert.state && (
        <Notification
          className="mb-4 mt-4 bg-green-100"
          title={showErrorAlert.title}
          status={showErrorAlert.status}
          message={showErrorAlert.message}
        />
      )}

      {showSuccessAlert.state && (
        <Notification
          className="mb-4 mt-4 bg-green-100"
          title={showSuccessAlert.title}
          status={showSuccessAlert.status}
          message={showSuccessAlert.message}
        />
      )}
      {payments && (
        <div className="mt-8">
          <Table>
            <Table.Header>
              <Table.Heading>Customer</Table.Heading>
              <Table.Heading>Invoice Number</Table.Heading>
              <Table.Heading>Invoice Month</Table.Heading>
              <Table.Heading>Amount (GHS)</Table.Heading>
              <Table.Heading>Status</Table.Heading>
              <Table.Heading>Reference</Table.Heading>
              <Table.Heading>Method</Table.Heading>
              <Table.Heading>Date</Table.Heading>
              <Table.Heading>Description</Table.Heading>
              <Table.Heading>Actions</Table.Heading>
            </Table.Header>
            <Table.Body>
              {payments?.data?.map((payment, index) => (
                <tr key={index}>
                  <Table.Cell>
                    {payment?.invoice?.user ? (
                      <div className="flex flex-col">
                        <span>
                          {payment.invoice.user.first_name}{" "}
                          {payment.invoice.user.last_name}{" "}
                        </span>
                        <span className="text-xs text-gray-400">
                          Individual Customer
                        </span>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <span>{payment.invoice.organisation.name}</span>
                        <span className="text-xs text-gray-400">
                          Business Customer
                        </span>
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell>{payment.invoice.code}</Table.Cell>
                  <Table.Cell>
                    {format(parseISO(payment.invoice.issued_at), " MMMM yyyy")}
                  </Table.Cell>
                  <Table.Cell>{payment.amount}</Table.Cell>
                  <Table.Cell>
                    {payment.status === "Successful" ? (
                      <span className="px-2 py-1 text-sm font-bold text-green-600 bg-green-200 rounded-full">
                        {payment.status}
                      </span>
                    ) : payment.status === "Pending" ? (
                      <span className="px-2 py-1 text-sm font-bold text-orange-600 bg-orange-200 rounded-full pyt-1">
                        {payment.status}
                      </span>
                    ) : payment.status === "Failed" ? (
                      <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full pyt-1">
                        {payment.status}
                      </span>
                    ) : (
                      <span className="px-2 py-1 text-sm font-bold text-red-600 bg-red-200 rounded-full">
                        Failed
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell>{payment.payment_reference}</Table.Cell>
                  <Table.Cell>
                    {payment.channel !== null ? payment.channel : "N/A"}
                  </Table.Cell>
                  <Table.Cell>
                    {format(parseISO(payment.payment_date), "dd MMMM yyyy")}
                  </Table.Cell>
                  <Table.Cell>{payment.description}</Table.Cell>
                  <Table.Cell>
                    {payment.status !== "Successful" ? (
                      <Button
                        text="Verify"
                        className="w-full"
                        onClick={(e) => verifyPayment(e, payment.id)}
                      />
                    ) : (
                      <Button
                        text="Verify"
                        className="w-full"
                        onClick={(e) => verifyPayment(e, payment.id)}
                      />
                    )}
                  </Table.Cell>
                </tr>
              ))}
            </Table.Body>
          </Table>
          {payments.meta && (
            <div className="flex justify-center min-w-full my-4">
              {payments.meta.links.map((link, index) =>
                link.url ? (
                  <Table.Pagination
                    key={index}
                    is_active={link.active.toString()}
                    onClick={() => setCurrentPaginationLink(link.url)}
                  >
                    {link.label}
                  </Table.Pagination>
                ) : (
                  <Table.Pagination
                    key={index}
                    is_active={link.active.toString()}
                  >
                    {link.label}
                  </Table.Pagination>
                )
              )}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}
