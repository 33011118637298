import React from "react";
import { useState } from "react";
import Anchor from "../Anchor";

export default function Notification(props) {
  const [close, setClose] = useState(false);

  return (
    <div className={`${close ? "hidden" : ""}`}>
      <div
        className={`${
          props.className ? props.className : ""
        } flex fixed space-x-4 top-20 right-5 px-4 py-8 min-w-96 max-w-96 bg-white rounded-lg shadow-2xl`}
      >
        {/* <div className="w-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 fill-green-500"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div> */}

        <div className="w-full ">
          <p
            className={`${
              props.status === "success" ? " text-green-800" : "text-red-500"
            } font-bold`}
          >
            {props.title}
          </p>
          <span className=" text-sm font-bold text-gray-500">
            {props.message}
          </span>
          {props.callToAction && (
            <Anchor
              className="flex mt-2 text-sm text-brand-primary"
              to={props.callToAction.url}
              text={props.callToAction.text}
            />
          )}
        </div>

        <div className="">
          <button type="button" onClick={() => setClose(true)}>
            <span className="sr-only">Close panel</span>

            <span className="text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
